/** @format */
// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
//                               Basic
// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;700;800;900&display=swap");

* {
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: #000;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(
    93.5deg,
    #f89947 0%,
    #e730a8 31.67%,
    #7327d7 66.07%,
    #0a76eb 100%
  );
}

body {
  user-select: none;
  scroll-behavior: smooth;
  background: url("./BG.svg") no-repeat center, #27272a !important;
  background-size: cover;
}
a {
  text-decoration: none !important;
}
// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
//                               Navbar
// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================

.navbar {
  background: rgba(7, 7, 7, 0.75);
  /* Neutral/Neutral 01 */

  border-bottom: 1px solid #2c3a4b;
  backdrop-filter: blur(25px);
  button {
    background: linear-gradient(
      93.5deg,
      #f89947 0%,
      #e730a8 31.67%,
      #7327d7 66.07%,
      #0a76eb 100%
    );
    backdrop-filter: blur(12.5px);
    border-radius: 6px;
    padding: 8px 20px;
    border: none;

    span {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 170%;
      color: #fff;
    }
  }
}
.navbar-toggler {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding-right: 20px;
}

.navbar-toggler:active,
.navbar-toggler:focus {
  outline: none !important;
}
.navbar-toggler-icon {
  width: 24px !important;
  height: 17px !important;
  background-image: none !important;
  position: relative !important;
  border-bottom: 3px solid #fff !important;
  transition: all 300ms linear;
}
.navbar-toggler-icon:after,
.navbar-toggler-icon:before {
  width: 24px !important;
  position: absolute !important;
  height: 2px !important;
  background-color: #fff !important;
  top: 0 !important;
  left: 0 !important;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
.navbar-toggler-icon:after {
  top: 8px !important;
}

.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg) !important;
}
.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent !important;
}
.nav-link {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 170%;
  transition: all 0.4s;
  position: relative;
  display: block;
  color: #6d7580 !important;
  letter-spacing: 0.2px !important;
}
.nav-item:hover .nav-link {
  // -webkit-text-fill-color: transparent;
  // -text-fill-color: transparent;
  // -webkit-background-clip: text;
  // background-clip: text;
  // background-image: linear-gradient(93.5deg,#f89947,#e730a8 31.67%,#7327d7 66.07%,#0a76eb);
  color: #fff;
}
.nav-item > .active {
  color: #fff !important;
  border-bottom: 3.5px solid #e730a8;
}
.nav-item .nav-link::after {
  content: "";
  position: absolute;
  top: 0;
  left: 10%;
  height: 100%;
  width: 0%;
  border-bottom: 3.5px solid #e730a8;
  transition: all 0.4s;
}

.nav-item:hover .nav-link::after {
  width: 80%;
}
.nav-btn {
  border: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 170%;
  padding: 6px 25px;
  text-align: center;
  text-transform: uppercase;
  height: 40px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  transition: all 0.3s linear;
  background: linear-gradient(
    93.5deg,
    #f89947 0%,
    #e730a8 31.67%,
    #7327d7 66.07%,
    #0a76eb 100%
  );
  border-radius: 100px !important;
  color: #ffffff;

  &:hover {
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    transform: scaleX(1.1);
    box-shadow: 0px 5px 30px 0px rgba(73, 68, 68, 0.57) !important;
  }
}

// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
//                              Footer
// ======================°°°°°°°°°°°°°°°°°°°°°°°=========================
.footer-bg {
  padding-top: 30px;
  margin-top: 100px;
  video {
    object-fit: cover;
    width: 100%;
    height: 310px;
    position: absolute;
    bottom: 2;
    left: 0%;
  }
  .footer-ab-content {
    position: absolute;
    backdrop-filter: blur(7px);
    width: 100% !important;
    background: linear-gradient(180deg, #000001 0%, rgba(0, 0, 1, 0.44) 100%);
    left: 0;
    // right: 0
    padding: 0px 40px;
  }
  h3 {
    font-family: "poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 170%;
    text-transform: uppercase;
    color: #dadee3;
  }
  li {
    a {
      font-family: "poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 170%;
      letter-spacing: 0.005em;
      color: #858c94;
    }
  }
  svg {
    color: #a5abb3;
  }
  hr {
    height: 2px;
    background-color: #a5abb3;
    margin-top: 8px;
  }
  p {
    font-family: "poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.005em;
    color: #545d69;
  }
}
.footer-img {
  margin-top: -80px;
  margin-bottom: 30px;
}

.offcanvas {
  backdrop-filter: blur(10px) !important;
  background-color: #00000080 !important;
}

.banner {
  // background: linear-gradient(
  //   111.68deg,
  //   rgba(17, 23, 40, 0.15) 7.59%,
  //   rgba(49, 82, 165, 0.3) 102.04%
  // ) !important;
  height: 250px !important;
  width: 100% !important;
}

.box {
  // box-shadow: 0px 5px 30px 0px rgb(214 215 216 / 57%) !important;
  border-radius: 12px !important;
  background: url("./BG.svg") no-repeat center, #36363b;
  font-family: "Poppins", sans-serif !important;
  border: 1px solid #394452;

  input {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    padding-left: 10px;
    color: #cccfd3;
    background: #27272a;
    box-shadow: none !important;
  }

  h4 {
    font-weight: 500;
    font-style: normal;
    font-size: 18px;
    text-transform: none;
    letter-spacing: 0;
    color: #858c94;
    text-align: center;
  }

  button {
    border: none;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 170%;
    padding: 6px 25px;
    text-align: center;
    text-transform: uppercase;
    height: 45px;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    transition: all 0.3s linear;
    background: linear-gradient(
      93.5deg,
      #f89947 0%,
      #e730a8 31.67%,
      #7327d7 66.07%,
      #0a76eb 100%
    );
    border-radius: 100px;
    color: #ffffff;

    &:hover {
      -webkit-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      transform: scaleX(1.1);
      box-shadow: 0px 5px 30px 0px rgba(73, 68, 68, 0.57) !important;
    }
  }
}

.App {
  margin-top: -150px !important;

  .chat {
    // box-shadow: 10px 12px 15px 0 rgb(11, 20, 26),
    //   -10px 12px 15px 0 rgb(11, 20, 26) !important;

    // background: url("./card3.svg") no-repeat center,
    //   linear-gradient(111.68deg, #205998 7.59%, #060c3e 102.04%);
    background: url("./BG.svg") no-repeat center, #36363b;
    overflow-y: hidden !important;
    background-size: contain;
    object-fit: contain;
    margin-bottom: 20px;
    border: 1px solid #394452 !important;
    height:80vh !important;
  }
}

textarea {
  background: transparent !important;
  padding-top: 12px !important;
  background: #36363b !important;
  color: #fff !important;
}

.rn-14lw9ot {
  height: 60px !important;
  position: sticky !important;
}

.spp {
  margin-top: -150px !important;
}

.center-box {
  max-width: 500px;

  h1 {
    font-weight: 600;
    font-style: normal;
    font-size: 36px;
    text-transform: none;
    letter-spacing: 0;
    line-height: 38px;
    color: #25d366;
  }

  p {
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    text-transform: none;
    letter-spacing: 0;
  }
}

.ConversationList {
  p {
    font-size: 13px;
    color: #ffffff;
  }

  h6 {
    font-size: 17px;
    font-weight: 500;
    color: #ffffff;

  }

  ul {
    padding: 0px;
    width: 95%;

    li {
      padding: 10px 15px;
      list-style: none;
      border-radius: 3px;
      margin: 0px 10px;
      cursor: pointer;

      &:hover {
        background-color: #545d69;
      }

      .online-icon {
        color: rgb(134, 197, 65);
        font-size: 16px;
      }
    }

    .btn-login {
      border: none;
      border-radius: 32px;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: 1px;
      line-height: 170%;
      padding: 6px 25px;
      text-align: center;
      text-transform: uppercase;
      max-width: 350px;
      transition: all 0.3s linear;
      background-color: #25d366;
      color: #ffffff;

      &:hover {
        background-color: #ffffff;
        color: #25d366;
        box-shadow: 0px 5px 30px 0px rgb(214 215 216 / 57%) !important;
      }
    }
  }
}
